import { request_url } from "../assets/constants";
import CheckJWTTokenAction from "../helper/check_jwt_token";

export async function LoginService(email, password) {
  const URL = `${request_url}/login`;
  const response = await fetch(URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      email,
      password,
    }),
  });
  const content = await response.json();

  return content;
}

export async function RegisterService(email, password, tip_persoana, language) {
  const URL = `${request_url}/register`;
  const response = await fetch(URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email,
      password,
      tip_user: tip_persoana,
      language,
    }),
  });
  const content = await response.json();

  return content;
}

export async function LogoutService() {
  const URL = `${request_url}/logout`;
  const response = await fetch(URL, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  const content = await response.json();

  return content;
}

export async function UserMEService() {
  const URL = `${request_url}/user`;
  const response = await fetch(URL, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  const content = await response.json();

  return CheckJWTTokenAction(content);
}

export async function VerificationAccount(code, email) {
  const URL = `${request_url}/account`;
  const response = await fetch(URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      email,
      code_to_verify_account: code,
    }),
  });
  const content = await response.json();

  return content;
}

export async function ResendCode(email) {
  const URL = `${request_url}/resend_code`;
  const response = await fetch(URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      email,
    }),
  });
  const content = await response.json();

  return content;
}
