import { request_url } from "../assets/constants";
import CheckJWTTokenAction from "../helper/check_jwt_token";

export async function UsersGet(page) {
  const URL = `${request_url}/confirmed_user?page=${page}`;
  const response = await fetch(URL, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function UserRoleGET() {
  const URL = `${request_url}/role`;
  const response = await fetch(URL, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function UpdateUserStatus(user_id, status_acceptat) {
  const URL = `${request_url}/update_user_status/${user_id}`;
  const response = await fetch(URL, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify({
      status_acceptat,
    }),
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function CurrentUserGET() {
  const URL = `${request_url}/user`;
  const response = await fetch(URL, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function UpdateUserPreluatDe(user_id, preluat_de) {
  const URL = `${request_url}/update_user_preluat_de/${user_id}`;
  const response = await fetch(URL, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify({
      preluat_de,
    }),
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function BrokerAccountsGET() {
  const URL = `${request_url}/broker_accounts`;
  const response = await fetch(URL, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function UserByIdGET(id) {
  const URL = `${request_url}/get_user/${id}`;
  const response = await fetch(URL, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function UpdateNotiteByIdGET(user_id, notite) {
  const URL = `${request_url}/update_user_notite/${user_id}`;
  const response = await fetch(URL, {
    method: "PUT",
    credentials: "include",
    body: JSON.stringify({
      notite,
    }),
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function UserAllDataGET(id) {
  const URL = `${request_url}/get_user_all_data/${id}`;
  const response = await fetch(URL, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function TipContAlesGET() {
  const URL = `${request_url}/tip_cont_ales`;
  const response = await fetch(URL, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function DeleteUserByID(user_id) {
  const URL = `${request_url}/delete_user/${user_id}`;
  const response = await fetch(URL, {
    method: "DELETE",
    credentials: "include",
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function ChangeLanguage(language) {
  const URL = `${request_url}/change_lg`;
  const response = await fetch(URL, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      language,
    }),
  });
  const content = await response.json();
  return CheckJWTTokenAction(content);
}

export async function GetUsersWithFilter(page, email, preluat_de) {
  const URL = `${request_url}/confirmed_documents_user?page=${page}&email=${email}&preluat_de=${preluat_de}`;
  const response = await fetch(URL, {
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  const content = await response.json();
  console.log(content);
  return CheckJWTTokenAction(content);
}
