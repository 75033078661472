import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en/translation-en.json";
import ro from "./locale/ro/translation-ro.json";

const resources = {
    en: {
        translation: en,
    },
    ro: {
        translation: ro,
    },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'ro',
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;