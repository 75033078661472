import Button from "@mui/material/Button";
import React from "react";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { LoginService } from "../../services/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [buttonLoading, setButtonLoading] = React.useState(false);

  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();

  const [error, setError] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState<string>();

  function HasUpperCase(str: string) {
    return /[A-Z]/.test(str);
  }

  function HandleError(message: string) {
    setButtonLoading(false);
    setError(true);
    setErrorDescription(message);
    setTimeout(() => {
      setError(false);
      setErrorDescription(message);
    }, 3000);
  }

  async function SubmitLogin() {
    setButtonLoading(true);
    if (!email || !password) {
      return
    }

    const email_formated = email.toLowerCase();
    const regex_email =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const email_verification = regex_email.test(email_formated);
    const password_verification = HasUpperCase(password) && password.length > 4;

    if (!email_verification) {
      HandleError(t("LOGIN.email_validation"));
    } else if (!password_verification) {
      HandleError(t("LOGIN.pass_validation"));
    } else {
      const content = await LoginService(email_formated, password);
      if (content.error) {
        HandleError(content.error);
      } else if (content.response === "ROLE_USER") {
        setButtonLoading(false);
        window.location.replace(content.hasDocs ? "/docs" : "/home");
      } else if (content.response === "ROLE_ADMIN") {
        setButtonLoading(false);
        window.location.replace("/users");
      } else if (content.response === "ROLE_SUPER_ADMIN") {
        setButtonLoading(false);
        window.location.replace("/confirmed_users");
      } else {
        HandleError(t("LOGIN.500_error"));
      }
    }
  }

  return (
    <div>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-right">
                <div>
                  <h1>{t("LOGIN.title")}</h1>
                  <p className="account-subtitle">{t("LOGIN.sub_title")}</p>

                  <TextField
                    style={{ width: "100%", marginTop: 30 }}
                    variant="outlined"
                    label={t("LOGIN.label_email")}
                    onChange={(snap) => setEmail(snap.target.value)}
                  />

                  <TextField
                    style={{ width: "100%", marginTop: 30 }}
                    variant="outlined"
                    label={t("LOGIN.label_pass")}
                    type="password"
                    onChange={(snap) => setPassword(snap.target.value)}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 40,
                    }}
                  >
                    <p>
                      {t("LOGIN.sub_input_0")}{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          color: "rgb(30, 49, 107)",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/signup")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            SubmitLogin();
                          }
                        }}
                      >
                        {" "}
                        {t("LOGIN.sub_input_1")}
                      </span>
                    </p>
                  </div>

                  {error ? (
                    <p style={{ color: "red" }}>{errorDescription}</p>
                  ) : null}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignSelf: "center",
                      alignItems: "center",
                      marginTop: 10,
                      marginBottom: 25,
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        width: "100%",
                        padding: 10,
                        backgroundColor: "rgb(30, 49, 107)",
                        color: "white",
                        fontWeight: "700",
                      }}
                      onClick={SubmitLogin}
                      disabled={buttonLoading}
                    >
                      {buttonLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("LOGIN.button_title")
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
