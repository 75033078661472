import { front_url } from "../assets/constants";

export default function CheckJWTTokenAction(content) {
  if (
    content.error === "JWT has expired!" ||
    content.error === "Unauthenticated"
  ) {
    window.open(`${front_url}/login`, "_self");
  } else {
    return content;
  }
}
