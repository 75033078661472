import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import RO_FLAG from "./assets/RO.png";
import EN_FLAG from "./assets/UK.png";
import { ChangeLanguage } from "./services/users";
import { createTheme, ThemeProvider } from "@mui/material";
import { request_url } from "./assets/constants";
import Header from "./components/header";
import i18n from './i18n'
import loadable from '@loadable/component';

import Login from './pages/login';

const Home = loadable(() => import("./pages/home"));
const Docs = loadable(() => import("./pages/docs"));
const Verification = loadable(() => import("./pages/verification"));
const Instruction = loadable(() => import("./pages/instructions"));
const Signup = loadable(() => import("./pages/signup"));
const BrokerUserList = loadable(() => import("./pages/profile/index"));
const EditUser = loadable(() => import("./pages/profile/edit"));
const SuperadminUserList = loadable(() => import("./pages/superadmin_user_list"));

const theme = createTheme({
  palette: {
    primary: {
      main: '#47519e',
      light: '#787dd0',
      dark: '#0c296f'
    },
    secondary: {
      main: '#47519e',
      light: '#787dd0',
      dark: '#0c296f'
    },
    warning: {
      main: '#ff9800'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          fontWeight: 'bold'
        }
      }
    }
  }
})

const NotFound = () => <div>Not found page</div>;

function RequireAuth({ children, isLogged }: { children: React.ReactElement, isLogged: boolean }) {
  return isLogged ? <>
    <Header />
    {children}</> : <Navigate to={"/login"} />;
}

const AppRouter = () => {
  const [isLogged, setIsLogged] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    isUserAuthenticated();
  }, []);

  async function isUserAuthenticated() {
    const URL = `${request_url}/user`;
    const response = await fetch(URL, {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const content = await response.json();

    if (content.response) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }

  React.useEffect(() => {
    const local_lg = localStorage.getItem("language");
    if (!local_lg) {
      return
    }
    i18n.changeLanguage(local_lg);
  }, []);

  async function changeLanguage(ln: "en" | "ro") {
    ChangeLanguage(ln);

    i18n.changeLanguage(ln);
    localStorage.setItem("language", ln);
  }

  if (isLogged === null) {
    return <div>Loading...</div>
  }

  return (
    <div className="main-wrapper">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/home" element={<RequireAuth isLogged={isLogged}><Home /></RequireAuth>} />
          <Route path="/docs" element={<RequireAuth isLogged={isLogged}><Docs /></RequireAuth>} />
          <Route path="/users" element={<RequireAuth isLogged={isLogged}><BrokerUserList /></RequireAuth>} />
          <Route path="/edit/users" element={<RequireAuth isLogged={isLogged}><EditUser /></RequireAuth>} />
          <Route path="/confirmed_users" element={<RequireAuth isLogged={isLogged}><SuperadminUserList /></RequireAuth>} />

          <Route path="/login" element={<Login />} />
          <Route path="/instruction" element={<Instruction />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/" element={<Navigate to="/instruction" />} />

          <Route element={<NotFound />} />
        </Routes>

        <div
          style={{
            position: "absolute",
            width: 150,
            height: 50,
            background: "rgba(0,0,0,0.2)",
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            top: "15%",
            right: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
              justifyContent: "flex-end",
            }}
          >
            <img
              src={RO_FLAG}
              alt="RO"
              style={{
                width: 60,
                borderRadius: 5,
                height: 30,
                cursor: "pointer",
              }}
              onClick={() => changeLanguage("ro")}
            />
            <img
              src={EN_FLAG}
              alt="EN"
              style={{
                width: 60,
                borderRadius: 5,
                height: 30,
                marginLeft: 10,
                marginRight: 10,
                cursor: "pointer",
              }}
              onClick={() => changeLanguage("en")}
            />
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default AppRouter;