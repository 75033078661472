import AppRouter from "./approuter";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./assets/App.css";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <AppRouter />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
reportWebVitals();
