import React from "react";
import "../../assets/App.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "../../assets/js/app";

import { LogoutService } from "../../services/auth";
import { request_url } from "../../assets/constants";
import { useTranslation } from "react-i18next";

import logo from "../../assets/bluerock-logo-big-300x63.png";
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Account menu state
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [email, setEmail] = React.useState("");

  function logout() {
    LogoutService();
    navigate("/login", { replace: true });
  }

  async function isUserAuthenticated() {
    const URL = `${request_url}/user`;
    const response = await fetch(URL, {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const content = await response.json();

    if (content.response) {
      setEmail(content.response.email);
    } else {
      navigate("/login", { replace: true });
    }
  }

  React.useEffect(() => {
    isUserAuthenticated();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="header">
        {/* Logo */}
        <div className="header-left">
          <a href="/" className="logo">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        {/* Mobile Menu Toggle */}
        <a href="#0" className="mobile_btn" id="mobile_btn">
          <i className="fa fa-bars" />
        </a>
        {/* /Mobile Menu Toggle */}

        <ul className="nav user-menu" style={{ listStyleType: "none" }}>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>{email.substring(0, 1).toUpperCase()}</Avatar>
            </IconButton>
          </Tooltip>
        </ul>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> {email}
          </MenuItem>

          <Divider />
          <MenuItem onClick={() => logout()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t("HEADER.deconectare")}
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
